import React, { useEffect, useState } from "react";
import { footerBg, footerTop, footerMobileTop, avatar } from "../../assets";
import { ConnectWallet, ConnectedWallet, Logo, Plus } from "../index";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectWallet, hide, show } from "../../features/WalletSlice";

const svgSmall = (
	<svg
		width="344"
		height="254"
		viewBox="0 0 344 254"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M214.169 234.737L200.303 243.204L186.498 248.826L183.956 247.876L181.484 247.584L179.079 246.988L176.731 244.995L174.438 240.367L172.184 232.507L169.961 223.323L167.774 213.809L165.621 204.673L163.511 196.427L161.444 189.397L159.423 183.702L157.458 179.287L155.538 175.904L153.666 173.14L141.761 172.366L140.029 168.885L138.293 163.42L136.553 156.533L134.82 148.817"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M44.1514 136.745L44.6458 137.014L44.9961 137.205L57.5663 144.076L58.3838 144.878L62.7321 147.533L62.9462 147.564L65.1496 149.086L69.0541 151.589L70.9033 152.722L71.8687 153.442L72.3748 153.8L76.4506 156.673L79.199 158.518L84.0963 161.796L84.5206 162.197L85.2252 162.867L86.3503 163.649L91.8743 167.499L93.8908 168.905L98.3949 172.042L98.9048 172.346L103.701 175.168L111.343 179.672L111.666 179.789L124.053 184.243L136.592 185.862L149.061 187.879L161.444 189.397L173.749 189.572L186.008 187.91L198.251 184.219L210.51 178.637L222.815 171.622L235.171 163.957L247.547 156.79L259.88 151.476L272.131 148.296L284.276 146.793L296.325 146.489"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M73.819 227.82L59.2168 230.615"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M46.9619 151.126L60.5948 155.4L74.2433 162.298L87.8295 171.089L88.0669 171.256L101.275 180.665L101.517 180.829L114.515 189.572L127.517 196.034L140.325 199.561L153.027 202.738L165.621 204.673L178.133 204.614L190.602 202.193L203.074 197.397L215.582 190.572L228.152 182.444L240.773 174.125L253.39 167.133L265.917 162.987L278.32 161.278L290.602 161.247L302.767 162.209"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M85.8868 223.697L71.6077 224.199L57.457 227.894"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M77.1983 180.937L63.5031 177.064L49.9053 176.476"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M298.423 182.865L284.63 184.356L270.577 190.475L256.395 200.437L242.221 212.201L228.137 224.075L214.169 234.737L211.249 233.877L208.388 234.57L205.597 235.656L202.895 235.695L200.299 233.005L197.791 226.656L195.351 218.819L192.957 210.446L190.601 202.193L188.285 194.567L186.008 187.91L183.766 182.405L181.566 178.096L179.409 174.861L177.295 172.447L175.228 169.734L173.192 164.786L171.188 158.071L169.21 150.157"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M48.3545 162.696L61.9796 165.16L75.6631 170.621L89.3154 178.489L89.4244 178.563L102.859 187.707L116.215 196.805L129.346 203.925L142.274 208.27L155.082 211.859L167.773 213.809L180.382 213.435L192.956 210.446L205.541 204.914L218.182 197.307L230.888 188.486L243.645 179.723L256.39 172.739L269.015 169.107L281.499 168.04L293.848 168.644L306.075 170.139"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M221.83 151.811L233.555 144.664L245.304 137.917L248.146 139.093L251.046 140.487L253.966 143.06L256.913 146.762L259.879 151.476L262.877 156.981L265.917 162.987L269.016 169.107L272.192 174.861L275.486 179.68L280.842 179.614"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M329.554 192.091L315.913 191.238L302.124 191.514L288.223 192.678L274.072 197.887L259.781 206.728L245.483 217.34L231.254 228.178L217.127 238.011L203.085 245.914L189.098 251.235L175.111 253.579L161.054 252.796L149.601 249.713L146.857 248.973L132.48 242.924L117.928 236.454L103.248 231.121L88.5177 228.03L73.8182 227.82L72.701 226.076L71.607 224.199L70.4898 221.435L69.3102 217.122L68.0334 210.687L66.6319 201.624L65.0865 189.825L63.5021 177.064L61.9799 165.16L60.5941 155.4L59.3873 148.56L58.3401 144.71L57.5654 144.076"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M212.588 160.107L224.407 153.255L236.268 145.909L239.04 147.159L241.843 149.397L244.677 152.632L247.546 156.79L250.446 161.707L253.389 167.133L256.391 172.739L259.462 178.088L262.635 182.658L265.944 185.687L267.633 185.305"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M343 194.341L329.554 192.091L325.51 184.756L312.06 183.051L298.423 182.864L294.553 178.574L290.575 178.006L287.5 178.512L280.843 179.614L277.071 180.237L276.471 180.56L274.124 181.821L267.634 185.305L267.42 185.422L266.307 186.018L265.82 186.282L263.321 187.622L259.681 190.074L256.314 192.713L252.946 195.598L251.436 197.023L249.47 198.79L249.291 198.954L244.176 203.652L237.492 209.791L235.662 211.473L234.478 212.54L230.776 215.888L223.586 222.385L221.963 223.853L218.961 225.616L216.057 226.609L213.282 224.915L210.631 219.43L208.057 212.466L205.543 204.914L203.075 197.397L200.641 190.385L198.251 184.219L195.892 179.092L193.572 175.075L191.291 172.093L189.056 169.967L177.296 172.447L165.508 173.381L153.666 173.14L151.825 169.858L140.029 168.885L128.187 168.36L116.333 169.235L104.374 168.414L92.2557 165.21L90.975 160.854L78.8642 157.37L77.7275 153.345L76.5674 148.463"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M321.293 181.292L324.026 182.059L334.361 184.963L338.76 187.91"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M290.574 178.006L298.983 178.345L303.868 178.543L308.022 179.034L312.059 183.051L315.913 191.238"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M245.304 137.917L257.037 132.335L268.727 127.967L271.814 129.446L283.559 126.503"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M198.496 163.766L210.144 158.386L221.83 151.811L224.407 153.255L227.043 154.571L229.717 156.778L232.427 159.92L235.171 163.957L237.951 168.765L240.773 174.125L243.646 179.723L246.589 185.15L249.618 189.895L252.767 193.196L256.313 192.714L259.681 190.074"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M93.7734 168.087L96.0391 168.788L105.752 171.793L117.835 172.891L129.806 171.976L141.761 172.365L143.513 175.149L155.538 175.904L167.501 176.017L179.409 174.861L191.29 172.093L203.175 167.628L215.087 161.637L227.043 154.571L239.04 147.159L251.046 140.487L263.017 135.36L274.929 131.708L286.767 129.341"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M189.056 169.967L200.808 165.81L212.588 160.107L215.087 161.637L217.626 164.023L220.203 167.351L222.815 171.622L225.466 176.722L228.152 182.444L230.889 188.486L233.684 194.419L236.557 199.721L239.535 203.594L242.704 203.633L246.036 201.266L249.469 198.79L252.942 198.062L260.7 192.11L266.976 187.299"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M90.9746 160.854L102.949 163.112L114.787 163.525L126.548 162.762L138.293 163.42L149.987 164.541L161.619 165.214L173.192 164.786L184.727 162.871L196.25 159.317L207.788 154.229L219.358 147.949L230.963 141.094L242.587 134.597L254.199 129.225L265.769 125.094L277.284 122.167L280.371 124.744"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M119.33 175.301L131.433 174.643"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M289.959 133.768L278.043 135.473L266.041 138.474L253.966 143.06L241.843 149.397L229.717 156.778L217.626 164.023L205.581 170.298L193.571 175.075L181.566 178.096L169.537 179.4L157.457 179.287L145.307 178.353L133.099 177.481L120.852 177.574L111.591 175.721L108.975 175.196L108.465 175.094L107.103 173.666L105.752 171.793L104.374 168.414L102.949 163.112L101.493 156.44L100.025 148.934"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M293.14 139.572L281.154 140.576L269.078 142.846L256.913 146.762L244.678 152.632L232.427 159.92L220.203 167.351L208.03 173.962L195.892 179.092L183.766 182.405L171.62 183.857L159.424 183.702L147.157 182.491L134.817 181.085L122.422 180.393L120.853 177.574L119.331 175.301L107.376 173.701L107.103 173.666L106.41 173.413L100.762 171.342L96.8727 169.944L96.2304 169.687L96.2032 169.668L93.774 168.087L93.4276 167.865L92.9409 167.546L87.3585 163.918L85.0345 162.404L85.0072 162.388L84.0379 161.894L79.199 158.518L79.1523 158.398L76.7971 156.774L76.4974 156.568L72.3593 153.722L71.8532 153.403L69.0541 151.589L65.1496 149.086L62.7321 147.533L60.2718 145.828L58.341 144.71L45.1363 138.194L44.8482 138.05L44.1514 136.745"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M151.824 169.858L163.553 170.361L175.228 169.734L186.864 167.581L198.496 163.766L200.808 165.81L203.175 167.628L205.581 170.298L208.03 173.961L210.509 178.637L213.028 184.243L215.582 190.572L218.182 197.307L220.833 204.003L223.551 210.087L226.357 214.716L229.328 215.502L232.442 213.712L234.532 211.796L236.997 209.542L243.439 203.645L246.036 201.266L248.913 198.907L251.435 197.023L254.347 194.858L257.15 192.597L263.32 187.622L266.976 187.299L272.726 184.464L273.858 183.908L277.954 181.887L280.893 180.435L294.553 178.575L308.022 179.034L310.724 179.493L321.293 181.292L325.509 184.756L338.761 187.91L343 194.341"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M96.8444 169.901L96.2021 169.668"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M298.422 182.865L302.124 191.515"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M69.2012 151.62L69.3375 151.648L69.5087 151.683L77.7266 153.345L89.6622 155.303L101.493 156.44L113.226 156.381L124.893 155.77L136.552 156.533L148.161 157.713L159.703 158.433L171.187 158.071L182.632 156.257L194.062 152.862L205.499 147.992L216.967 141.997L228.467 135.484L239.982 129.349L251.478 124.366L262.931 120.715L274.317 118.414"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M191.917 144.948L194.062 152.862L196.25 159.317L198.496 163.766"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M203.269 140.21L205.499 147.992L207.788 154.228L210.143 158.386L212.588 160.107"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M214.647 134.456L216.968 141.997L219.358 147.949L221.83 151.811"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M226.054 128.302L228.467 135.484L230.963 141.094L233.555 144.664L236.269 145.909L248.146 139.093L260.004 133.604L263.017 135.36L266.041 138.474L269.078 142.846L272.13 148.296L275.205 154.555L278.32 161.278L281.5 168.04L284.766 174.327L297.192 175.46L298.983 178.345"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M237.464 122.603L239.983 129.349L242.587 134.597L245.304 137.917"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M248.858 118.06L251.478 124.366L254.199 129.224L257.037 132.335L260.004 133.604L271.815 129.446L274.929 131.708L278.043 135.472L281.154 140.576L284.276 146.793L287.417 153.812L290.602 161.247L293.848 168.644L297.192 175.46L309.494 177.418"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M260.198 114.957L262.931 120.715L265.769 125.094L268.728 127.967L280.371 124.744L283.559 126.503L286.767 129.341L289.959 133.767L293.14 139.572L296.324 146.489L299.524 154.17L302.767 162.209L306.076 170.139L309.494 177.418L310.724 179.493"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M271.46 113.376L274.317 118.414L277.284 122.167"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M90.9743 160.854L89.6624 155.303L88.3311 148.919"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M151.824 169.858L149.987 164.541L148.161 157.713L146.347 149.958"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M231.255 228.178L228.137 224.074L225.03 222.922L221.962 223.853L220.32 225.153L217.419 227.438L215.306 229.108L212.468 231.35L209.805 233.452L208.388 234.57L206.609 235.64L204.226 237.073L201.649 238.618L196.083 241.966L194.911 242.671L192.322 242.939L189.807 241.954L187.37 238.264L184.996 231.097L182.668 222.498L180.382 213.435L178.132 204.614L175.921 196.544L173.749 189.572L171.62 183.857L169.537 179.4L167.501 176.017L165.508 173.381L163.554 170.36L161.619 165.214L159.703 158.433L157.808 150.596"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M287.499 178.512L284.766 174.327L272.192 174.861L259.462 178.088L246.588 185.15L233.683 194.419L220.833 204.003L208.057 212.466L195.35 218.819L182.667 222.498L169.961 223.323L157.173 221.521L144.272 217.71L131.23 212.75L117.983 205.229L104.525 196.233L90.9001 187.653L77.1972 180.938L75.6634 170.621L74.2425 162.298L72.9773 156.533L71.8522 153.403L70.9023 152.722"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M266.976 187.299L270.577 190.475L274.072 197.887"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M217.128 238.011L214.169 234.737"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M252.941 198.062L256.394 200.437L259.781 206.728"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M46.1558 145.431L42.3213 143.846"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M203.086 245.914L200.303 243.204L197.578 242.406L194.911 242.671L193.218 243.29L188.616 244.972L181.485 247.584L168.035 249.094L165.8 247.685L163.609 244.808L161.448 239.409L159.299 231.035L157.174 221.521L155.083 211.859L153.028 202.738L151.019 194.652L149.061 187.879L147.157 182.491L145.308 178.353L143.513 175.149L131.434 174.643L129.807 171.976L128.187 168.36L126.548 162.762L124.894 155.77L123.247 147.992"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M277.07 180.237L280.893 180.435L284.63 184.356L288.223 192.678"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M238.936 210.527L242.221 212.201L245.483 217.34"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M46.0232 144.504L42.8154 142.966"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M189.099 251.235L186.498 248.826L172.686 251.227L158.804 250.316L156.624 248.736L154.499 247.327L152.424 245.295L150.38 241.787L148.34 235.87L146.297 227.228L144.272 217.71L142.275 208.269L140.325 199.561L138.429 192.021L136.592 185.862L134.817 181.085L133.1 177.481L131.434 174.643"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M132.48 242.924L130.576 240.102L128.739 238.058L126.948 236.1L125.897 235.59L125.041 235.169L113.716 229.649L112.907 229.252L111.295 226.235L109.664 221.684L107.982 214.833L106.246 205.611L104.525 196.233L102.859 187.707L101.275 180.665L99.7837 175.433L98.394 172.042L97.0938 170.248"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M146.857 248.973L144.782 246.28L142.773 244.434L140.819 242.733L139.834 242.262L128.315 236.754L126.948 236.1L125.185 233.488L123.421 229.423L121.627 223.066L119.797 214.284L117.983 205.229L116.216 196.805L114.514 189.572L112.887 183.842L111.342 179.672L109.87 176.893L105.133 174.425L97.8101 170.617L97.0938 170.248"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M175.111 253.579L172.686 251.227L170.327 249.986L168.034 249.094L159.707 248.004L154.72 247.354L154.498 247.327L153.393 246.953L143.941 243.78L140.982 242.788L140.819 242.733L138.899 240.417L136.996 236.656L135.084 230.564L133.146 221.926L131.23 212.75L129.346 203.925L127.517 196.034L125.753 189.428L124.052 184.242L122.421 180.392L112.548 177.636L109.87 176.893L108.465 175.094L107.974 174.876L104.424 173.307L97.6893 170.329L96.9964 170.115L96.2295 169.687"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M161.055 252.796L158.805 250.317L157.041 249.81L151.77 248.292L144.782 246.28L143.175 245.579L134.949 242.005L130.577 240.102L129.246 239.506L116.201 233.663L116.084 233.62L115.045 233.25L101.711 228.524L100.224 226.329L98.7645 223.841L97.293 220.369L83.2708 217.106L69.311 217.122L55.4912 220.517"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M117.929 236.454L116.2 233.663L114.534 231.491L112.907 229.252L109.921 228.112L98.7642 223.841L84.5941 221.027L70.4901 221.435L56.5225 225.134"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M189.056 169.967L186.864 167.581L184.727 162.871L182.633 156.257L180.569 148.307"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M103.249 231.121L101.711 228.524L99.9596 228.193L87.1832 225.791L72.7017 226.076L58.3408 229.458"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M93.4272 167.865L92.2554 165.21L85.0302 162.396L85.0068 162.388"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M119.33 175.301L117.835 172.891L116.333 169.236L114.787 163.525L113.226 156.381L111.653 148.424"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M45.6295 142.005L43.2354 140.747"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M88.5183 228.03L87.183 225.792L85.8867 223.697"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M62.8916 147.529L64.7329 147.681L76.5673 148.463L88.3316 148.918L100.026 148.934L111.654 148.424L123.247 147.992L134.82 148.817L146.347 149.958L157.808 150.596L169.21 150.156L180.57 148.307L191.917 144.948L203.269 140.21L214.648 134.456L226.054 128.302L237.464 122.603L248.859 118.06L260.199 114.957L271.461 113.376"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M43.6406 138.081L45.0031 138.832L45.6299 142.005L45.7817 142.776L59.3873 148.56L66.1843 152.547L72.9774 156.533L79.9963 161.387L86.4624 165.864L87.1202 166.335L95.3809 172.268L99.7838 175.433L100.15 175.667L112.887 183.842L125.753 189.428L138.428 192.021L151.018 194.652L163.51 196.427L175.921 196.544L188.285 194.566L200.641 190.386L213.028 184.243L225.466 176.722L237.95 168.764L250.446 161.707L262.876 156.981L275.205 154.555L287.417 153.812L299.524 154.17"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M267.42 185.422L265.945 185.687L265.186 186.119L262.406 187.704L262.172 187.84L252.767 193.196L239.535 203.594L234.186 208.11L226.358 214.716L218.798 220.614L213.282 224.916L200.299 233.005L187.371 238.264L174.438 240.367L161.448 239.409L148.341 235.87L135.085 230.564L121.628 223.067L107.983 214.833L94.21 207.705L92.5516 197.794L90.9011 187.653L89.3166 178.489L87.8296 171.089L86.4632 165.864L85.2252 162.867L83.9795 161.987"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M59.2166 230.615L58.3407 229.459L57.457 227.894L56.5227 225.134L55.4911 220.517L54.331 213.513L53.0035 203.695L51.497 190.861L49.9048 176.476L48.3554 162.696L46.9617 151.126L46.3662 146.926L46.3078 146.505L46.1559 145.431L46.0236 144.504L45.7822 142.776"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M65.1178 148.969L64.7324 147.681"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M276.471 180.56L275.486 179.68L262.636 182.658L249.618 189.895L236.557 199.721L223.551 210.087L210.631 219.43L197.792 226.656L184.996 231.097L172.184 232.507L159.299 231.035L146.297 227.228L133.147 221.926L119.798 214.284L106.247 205.611L92.5515 197.794L78.8018 192.239L65.0871 189.825L51.4971 190.861"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M79.26 158.472L78.863 157.37L76.4961 156.568"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M40.8574 145.625L41.5465 145.688"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M40.8574 145.625L41.3946 145.952L46.3659 146.925"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M46.307 146.505L44.5241 145.952L41.8652 145.127"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M41.3945 145.952L41.5464 145.687L41.8656 145.127L42.321 143.846L42.8154 142.966L43.2359 140.747L43.6407 138.081L44.1507 136.745"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M252.942 198.062L246.624 203.684L238.936 210.528L235.662 211.474L232.442 213.712L230.165 215.725L222.905 222.132L220.429 224.32L218.961 225.616L216.929 227.142L206.399 235.053L205.597 235.656L203.806 236.637L194.786 241.589L192.731 242.714L192.322 242.94L179.079 246.988L165.8 247.685L152.424 245.295L151.466 244.952L138.9 240.417L138.04 239.981L126.587 234.196L125.185 233.488L124.458 233.11L111.296 226.235L97.2929 220.369L95.7864 215.222L81.881 211.31L68.034 210.687L54.3311 213.513"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M256.135 192.737L255.527 193.231L251.121 196.805L249.275 198.3L242.704 203.633L236.394 209.231L233.007 212.236L229.328 215.502L221.866 221.75L216.058 226.609L202.896 235.695L189.808 241.954L176.732 244.995L163.609 244.808L150.381 241.787L136.997 236.656L136.374 236.325L123.422 229.423L109.665 221.684L95.7867 215.222L94.2101 207.705L80.3943 203.037L66.6329 201.624L53.0039 203.695"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M238.935 210.527L232.43 216.324L225.03 222.922L223.671 224.004L218.509 228.104L211.249 233.877L208.469 235.609L204.942 237.813L201.801 239.771L197.577 242.406L194.307 243.718L190.87 245.1L183.956 247.876L170.327 249.986L164.674 249.472L156.624 248.736L153.946 247.903L143.544 244.672L142.773 244.434L141.893 244.033L133.971 240.436L129.397 238.358L128.739 238.058L126.773 237.151L114.986 231.701L114.534 231.49L111.307 230.326L100.461 226.414L100.224 226.329L96.3113 225.612L85.8862 223.697L84.5937 221.026L83.2702 217.106L81.8804 211.31L80.3933 203.037L78.8011 192.238L77.1973 180.937"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M45.0034 138.832L44.8477 138.05"
			stroke="black"
			strokeWidth="0.717573"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M171.456 183.699V108.354" stroke="black" strokeWidth="0.717573" />
		<path
			d="M197.458 92.3197C196.272 96.9772 184.878 100.631 170.997 100.631C156.864 100.631 145.309 96.8426 144.483 92.0644C143.99 93.8607 144.025 95.7871 144.175 97.6367C144.5 102.063 148.146 103.951 151.987 105.318C158.042 107.402 164.617 108.053 170.997 108.053C177.378 108.053 183.953 107.467 190.008 105.318C193.654 104.017 196.648 102.389 197.56 98.9386C197.69 98.2874 197.811 97.7012 197.821 97.1156C197.849 95.5029 197.742 93.8913 197.458 92.3197H197.458Z"
			fill="#FF003C"
		/>
		<path
			d="M170.997 100.631C185.667 100.631 197.559 96.5501 197.559 91.5166C197.559 86.4827 185.667 82.4024 170.997 82.4024C156.328 82.4024 144.436 86.4827 144.436 91.5166C144.436 96.5501 156.328 100.631 170.997 100.631Z"
			fill="black"
		/>
		<path
			d="M197.742 92.3308C197.805 92.0627 197.837 91.7914 197.837 91.5166C197.837 86.2496 186.048 82.1238 170.997 82.1238C155.947 82.1238 144.157 86.2496 144.157 91.5166C144.157 91.6974 144.171 91.8764 144.199 92.0544C143.701 93.923 143.755 95.8966 143.898 97.6567C144.235 102.239 147.941 104.173 151.897 105.581C157.274 107.432 163.522 108.331 170.997 108.331C178.743 108.331 184.813 107.457 190.101 105.58C193.537 104.353 196.851 102.707 197.829 99.0092C197.892 98.799 198.088 97.7491 198.098 97.1251C198.125 95.568 198.032 93.9602 197.742 92.3308ZM170.997 82.6799C185.49 82.6799 197.281 86.6439 197.281 91.5166C197.281 96.3893 185.49 100.353 170.997 100.353C156.505 100.353 144.714 96.3888 144.714 91.5166C144.714 86.6445 156.504 82.6799 170.997 82.6799ZM197.542 97.1151C197.532 97.7007 197.406 98.2896 197.289 98.8752C196.374 102.319 193.204 103.881 189.914 105.056C184.689 106.911 178.677 107.775 170.997 107.775C163.584 107.775 157.396 106.885 152.08 105.056C148.304 103.712 144.766 101.88 144.452 97.6139C144.337 96.1969 144.281 94.6409 144.536 93.1299C146.662 97.6022 157.523 100.909 170.997 100.909C184.24 100.909 194.957 97.7151 197.341 93.3602C197.517 94.6326 197.563 95.8877 197.542 97.1151Z"
			fill="black"
		/>
		<path
			d="M170.737 98.2868C183.681 98.2868 194.174 95.1386 194.174 91.2558C194.174 87.3724 183.681 84.2248 170.737 84.2248C157.793 84.2248 147.3 87.3724 147.3 91.2558C147.3 95.1386 157.793 98.2868 170.737 98.2868Z"
			fill="black"
		/>
		<path
			d="M170.738 98.5649C157.439 98.5649 147.022 95.3544 147.022 91.2558C147.022 87.1572 157.44 83.9467 170.738 83.9467C184.036 83.9467 194.453 87.1572 194.453 91.2558C194.453 95.3544 184.036 98.5649 170.738 98.5649ZM170.738 84.5029C158.184 84.5029 147.579 87.5954 147.579 91.2558C147.579 94.9162 158.184 98.0088 170.738 98.0088C183.291 98.0088 193.897 94.9162 193.897 91.2558C193.897 87.5954 183.291 84.5029 170.738 84.5029Z"
			fill="#FF003C"
		/>
		<path
			d="M131.766 75.5388C133.466 73.2949 134.101 70.4592 133.509 67.7592C132.5 62.9054 127.932 59.6644 123.066 60.1732C126.785 56.8671 130.033 53.4648 130.519 48.9691C130.887 45.4783 129.387 41.7651 126.403 38.781C123.95 36.3285 120.852 34.6674 118.177 33.4662C121.194 33.4261 124.088 33.0507 126.797 32.3461C129.182 31.7105 132.586 30.5354 134.635 27.682C136.309 25.3501 136.704 22.0129 135.718 18.5249C134.94 15.7721 133.443 13.3235 131.921 10.9689C135.973 12.6851 139.49 16.2092 143.915 16.2092C146.902 16.3749 150.508 14.314 151.753 10.6236C152.097 9.61199 152.237 8.54187 152.164 7.4757C152.09 6.40952 151.805 5.36867 151.325 4.41392C151.292 4.34792 151.234 4.29772 151.164 4.27435C151.094 4.25099 151.018 4.25639 150.952 4.28935C150.886 4.32232 150.835 4.38015 150.812 4.45013C150.789 4.52011 150.794 4.59651 150.827 4.66251C151.274 5.55168 151.54 6.52105 151.608 7.51398C151.677 8.50692 151.546 9.50352 151.225 10.4456C150.075 13.8579 146.722 15.8088 143.93 15.6537C139.387 15.6531 135.773 11.7102 131.312 10.1353C131.257 10.1159 131.198 10.1141 131.142 10.1303C131.086 10.1464 131.036 10.1797 131.001 10.2253C130.965 10.271 130.944 10.3268 130.941 10.3848C130.939 10.4429 130.954 10.5003 130.986 10.549C132.639 13.0922 134.348 15.7221 135.183 18.6762C136.122 22.0001 135.758 25.1638 134.183 27.3572C130.904 31.9251 122.44 33.0174 116.837 32.9056C116.538 32.8939 116.438 33.3193 116.723 33.4395C119.589 34.6518 123.235 36.3997 126.009 39.1742C128.831 41.9965 130.31 45.6357 129.965 48.9096C129.47 53.4909 125.909 56.9505 121.986 60.3829C121.772 60.5703 121.946 60.9212 122.225 60.8645C127.134 59.8579 131.952 63.0022 132.965 67.8749C133.523 70.4186 132.924 73.088 131.322 75.2023C131.098 75.4982 131.548 75.8246 131.766 75.5382L131.766 75.5388Z"
			fill="black"
		/>
		<path
			d="M195.672 83.7215C195.739 83.7214 195.803 83.6976 195.853 83.6545C195.903 83.6114 195.936 83.5517 195.946 83.4863C196.46 80.2102 200.017 78.2526 203.159 78.2526C205.986 78.2526 208.909 79.5312 212.096 82.1605C212.35 82.3696 212.693 82.0393 212.494 81.7779C210.256 78.836 208.834 75.303 208.382 71.5614C208.052 68.9187 208.416 66.9851 209.495 65.6499C211.633 63.0278 215.827 63.5244 218.136 64.0533C218.406 64.1155 218.591 63.783 218.394 63.5856C216.616 61.8071 215.197 59.5682 214.292 57.1107C212.223 50.7737 217.225 46.243 222.596 44.1192C222.879 44.0074 222.801 43.5853 222.497 43.5825C215.822 43.5186 209.576 39.9533 206.197 34.2786C202.944 28.5373 202.944 21.3578 206.201 15.5469C206.229 15.497 206.241 15.4398 206.235 15.383C206.229 15.3261 206.206 15.2724 206.169 15.2292C206.132 15.1859 206.082 15.1552 206.026 15.1412C205.971 15.1272 205.913 15.1306 205.859 15.1509C200.155 17.317 194.47 17.4799 189.851 15.6086C184.444 13.4186 180.794 8.30786 180.976 3.18099C180.989 2.81674 180.433 2.78782 180.42 3.16153C180.231 8.51696 184.024 13.8479 189.643 16.1241C194.205 17.9721 199.764 17.8965 205.368 15.9228C202.369 21.7821 202.485 28.8554 205.716 34.5584C208.989 40.0545 214.866 43.6264 221.264 44.0891C216.31 46.4098 211.726 51.0456 213.766 57.2936C214.596 59.5459 215.839 61.6152 217.388 63.3336C214.873 62.8821 211.136 62.7586 209.063 65.2995C207.883 66.7599 207.48 68.8298 207.83 71.6293C208.219 74.857 209.313 77.934 211.018 80.6273C208.269 78.6581 205.685 77.6965 203.158 77.6965C199.783 77.6965 195.956 79.8298 195.396 83.4001C195.39 83.4398 195.393 83.4804 195.404 83.519C195.415 83.5576 195.434 83.5935 195.46 83.624C195.486 83.6544 195.519 83.6789 195.555 83.6957C195.592 83.7124 195.632 83.721 195.672 83.721L195.672 83.7215Z"
			fill="black"
		/>
		<path
			d="M168.362 56.8146V48.8696C171.393 48.8084 173.72 48.0736 175.342 46.6652C176.996 45.2569 177.822 43.4352 177.822 41.2002V40.0061C177.822 37.9855 177.149 36.3475 175.802 35.0922C174.454 33.8063 172.556 33.1634 170.107 33.1634C167.749 33.1634 165.882 33.7145 164.504 34.8167C163.157 35.8882 162.193 37.4344 161.611 39.4551L160.692 39.2254C161.305 37.021 162.392 35.3218 163.953 34.1278C165.514 32.9031 167.566 32.2908 170.107 32.2908C172.862 32.2908 175.006 33.0409 176.536 34.5411C178.098 36.0413 178.879 38.0314 178.879 40.5113C178.879 43.2056 178.052 45.3487 176.399 46.9408C174.776 48.5328 172.434 49.4666 169.372 49.7422V56.8146H168.362ZM168.867 65.127C167.826 65.127 167.306 64.7136 167.306 63.887V63.5196C167.306 62.6929 167.826 62.2796 168.867 62.2796C169.908 62.2796 170.428 62.6929 170.428 63.5196V63.887C170.428 64.7136 169.908 65.127 168.867 65.127Z"
			fill="black"
		/>
	</svg>
);

const svgBig = (
	<svg
		width="479"
		height="354"
		viewBox="0 0 479 354"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M298.463 327.127L279.139 338.926L259.901 346.76L256.359 345.436L252.914 345.029L249.561 344.199L246.29 341.422L243.094 334.971L239.953 324.018L236.855 311.22L233.807 297.962L230.807 285.229L227.866 273.739L224.985 263.941L222.17 256.004L219.43 249.852L216.756 245.138L214.146 241.286L197.556 240.206L195.142 235.356L192.723 227.739L190.298 218.142L187.883 207.39"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M61.5278 190.567L62.2168 190.941L62.7051 191.207L80.2227 200.782L81.3619 201.9L87.4217 205.6L87.7201 205.643L90.7907 207.764L96.232 211.252L98.8089 212.831L100.154 213.835L100.86 214.334L106.54 218.338L110.37 220.909L117.194 225.477L117.786 226.036L118.768 226.969L120.336 228.059L128.034 233.425L130.844 235.383L137.121 239.756L137.831 240.179L144.515 244.112L155.165 250.389L155.615 250.552L172.877 256.758L190.352 259.015L207.728 261.825L224.985 263.941L242.134 264.185L259.218 261.868L276.279 256.725L293.363 248.946L310.511 239.17L327.731 228.488L344.977 218.5L362.164 211.095L379.236 206.663L396.163 204.569L412.953 204.146"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M102.873 317.486L82.5234 321.381"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M65.4448 210.607L84.4434 216.564L103.464 226.177L122.397 238.427L122.728 238.66L141.135 251.772L141.472 252L159.586 264.185L177.706 273.19L195.554 278.106L213.256 282.532L230.806 285.229L248.243 285.147L265.619 281.773L283.001 275.089L300.432 265.579L317.949 254.252L335.538 242.658L353.12 232.915L370.578 227.137L387.862 224.755L404.978 224.712L421.932 226.052"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M119.69 311.741L99.7909 312.441L80.0708 317.589"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M107.582 252.152L88.4962 246.754L69.5464 245.935"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M415.878 254.838L396.657 256.915L377.072 265.444L357.308 279.326L337.556 295.721L317.928 312.267L298.463 327.127L294.394 325.928L290.407 326.893L286.517 328.407L282.752 328.461L279.133 324.712L275.639 315.864L272.238 304.944L268.902 293.274L265.619 281.773L262.391 271.145L259.218 261.868L256.093 254.197L253.028 248.192L250.022 243.684L247.076 240.32L244.196 236.539L241.358 229.643L238.565 220.285L235.809 209.256"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M67.3872 226.73L86.375 230.164L105.444 237.776L124.47 248.74L124.622 248.843L143.344 261.586L161.957 274.265L180.256 284.187L198.272 290.242L216.121 295.244L233.807 297.962L251.378 297.441L268.901 293.274L286.441 285.565L304.056 274.965L321.763 262.671L339.541 250.46L357.303 240.727L374.897 235.665L392.295 234.179L409.503 235.02L426.543 237.103"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M309.139 211.562L325.48 201.601L341.852 192.2L345.813 193.838L349.854 195.78L353.923 199.366L358.03 204.525L362.164 211.095L366.341 218.766L370.578 227.137L374.897 235.665L379.323 243.684L383.913 250.4L391.378 250.308"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M459.262 267.695L440.252 266.507L421.036 266.892L401.664 268.514L381.943 275.773L362.028 288.093L342.102 302.882L322.273 317.985L302.585 331.689L283.017 342.702L263.525 350.118L244.033 353.384L224.443 352.293L208.482 347.997L204.658 346.966L184.623 338.535L164.344 329.519L143.886 322.087L123.357 317.779L102.872 317.486L101.315 315.056L99.7909 312.441L98.2339 308.589L96.5901 302.578L94.8106 293.61L92.8576 280.981L90.7039 264.537L88.4959 246.754L86.3747 230.164L84.4433 216.563L82.7616 207.032L81.3022 201.666L80.2227 200.782"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M296.26 223.122L312.73 213.574L329.261 203.337L333.123 205.079L337.029 208.198L340.979 212.706L344.977 218.5L349.019 225.352L353.12 232.915L357.303 240.727L361.583 248.181L366.005 254.55L370.616 258.771L372.97 258.239"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M478 270.83L459.262 267.695L453.626 257.474L434.882 255.098L415.878 254.837L410.485 248.859L404.941 248.067L400.655 248.772L391.378 250.307L386.121 251.175L385.286 251.626L382.015 253.383L372.971 258.239L372.673 258.402L371.121 259.232L370.443 259.6L366.96 261.467L361.888 264.885L357.195 268.563L352.502 272.583L350.397 274.568L347.658 277.031L347.408 277.259L340.28 283.807L330.965 292.362L328.415 294.706L326.766 296.193L321.607 300.858L311.586 309.913L309.324 311.958L305.141 314.415L301.094 315.799L297.226 313.439L293.532 305.795L289.946 296.089L286.441 285.565L283.002 275.089L279.611 265.318L276.28 256.725L272.992 249.58L269.759 243.982L266.58 239.826L263.466 236.864L247.077 240.32L230.65 241.622L214.147 241.285L211.581 236.712L195.143 235.356L178.64 234.623L162.12 235.844L145.454 234.699L128.566 230.235L126.781 224.164L109.904 219.309L108.32 213.699L106.703 206.896"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M447.75 252.646L451.558 253.715L465.962 257.762L472.092 261.868"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M404.94 248.067L416.659 248.539L423.467 248.815L429.256 249.499L434.881 255.098L440.252 266.507"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M341.852 192.199L358.203 184.42L374.495 178.333L378.797 180.394L395.164 176.293"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M276.621 228.222L292.853 220.725L309.139 211.562L312.73 213.574L316.403 215.408L320.13 218.484L323.906 222.862L327.731 228.488L331.604 235.188L335.537 242.658L339.541 250.46L343.642 258.022L347.863 264.635L352.252 269.236L357.194 268.563L361.887 264.885"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M130.681 234.244L133.839 235.22L147.374 239.408L164.214 240.938L180.896 239.663L197.556 240.206L199.997 244.085L216.755 245.137L233.427 245.295L250.022 243.683L266.579 239.826L283.142 233.604L299.743 225.254L316.403 215.408L333.123 205.079L349.854 195.78L366.536 188.635L383.137 183.547L399.635 180.248"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M263.465 236.864L279.844 231.07L296.26 223.122L299.743 225.255L303.28 228.58L306.871 233.219L310.512 239.17L314.206 246.277L317.949 254.252L321.763 262.671L325.658 270.939L329.662 278.328L333.812 283.726L338.228 283.78L342.872 280.482L347.657 277.031L352.496 276.017L363.308 267.722L372.054 261.017"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M126.781 224.164L143.469 227.311L159.966 227.886L176.355 226.823L192.723 227.739L209.02 229.302L225.23 230.24L241.359 229.644L257.433 226.975L273.491 222.021L289.571 214.931L305.695 206.18L321.867 196.627L338.066 187.572L354.249 180.086L370.372 174.33L386.42 170.25L390.722 173.841"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M166.297 244.296L183.164 243.38"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M404.084 186.417L387.478 188.793L370.752 192.975L353.924 199.366L337.03 208.198L320.131 218.484L303.281 228.58L286.495 237.325L269.759 243.982L253.028 248.192L236.265 250.009L219.431 249.852L202.499 248.55L185.486 247.335L168.419 247.465L155.512 244.883L151.867 244.15L151.156 244.009L149.257 242.018L147.375 239.409L145.454 234.7L143.469 227.311L141.44 218.012L139.395 207.553"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M408.515 194.505L391.812 195.905L374.983 199.068L358.03 204.525L340.979 212.706L323.906 222.862L306.871 233.219L289.907 242.43L272.992 249.581L256.093 254.197L239.166 256.221L222.17 256.004L205.075 254.317L187.878 252.358L170.604 251.393L168.418 247.465L166.297 244.297L149.636 242.067L149.257 242.018L148.291 241.665L140.419 238.779L135 236.832L134.104 236.474L134.067 236.447L130.681 234.244L130.198 233.935L129.52 233.49L121.741 228.434L118.502 226.323L118.464 226.302L117.113 225.613L110.37 220.909L110.305 220.741L107.022 218.479L106.605 218.191L100.838 214.225L100.133 213.781L96.232 211.252L90.7907 207.764L87.4217 205.6L83.9931 203.223L81.3023 201.666L62.9004 192.585L62.4989 192.384L61.5278 190.567"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M211.58 236.712L227.926 237.412L244.196 236.539L260.411 233.539L276.621 228.222L279.844 231.07L283.142 233.604L286.495 237.325L289.907 242.43L293.363 248.946L296.873 256.758L300.432 265.579L304.056 274.964L307.75 284.296L311.537 292.775L315.448 299.225L319.588 300.321L323.928 297.826L326.841 295.157L330.275 292.015L339.254 283.796L342.872 280.482L346.881 277.194L350.397 274.568L354.455 271.552L358.361 268.4L366.96 261.467L372.054 261.017L380.066 257.067L381.645 256.291L387.352 253.476L391.448 251.452L410.485 248.859L429.256 249.499L433.021 250.139L447.75 252.646L453.625 257.474L472.092 261.868L478 270.831"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M134.962 236.772L134.067 236.447"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M415.877 254.838L421.037 266.892"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M96.438 211.296L96.6279 211.334L96.8666 211.383L108.319 213.699L124.952 216.428L141.439 218.012L157.79 217.931L174.049 217.079L190.297 218.142L206.475 219.786L222.56 220.79L238.564 220.285L254.514 217.757L270.442 213.026L286.381 206.24L302.363 197.885L318.388 188.809L334.436 180.259L350.456 173.315L366.417 168.226L382.285 165.02"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M267.453 201.997L270.442 213.026L273.491 222.021L276.621 228.222"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M283.272 195.395L286.381 206.24L289.571 214.931L292.853 220.725L296.26 223.122"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M299.13 187.377L302.363 197.885L305.694 206.18L309.139 211.561"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M315.026 178.8L318.389 188.809L321.867 196.626L325.48 201.601L329.261 203.337L345.813 193.838L362.338 186.189L366.537 188.635L370.752 192.975L374.984 199.068L379.237 206.663L383.523 215.386L387.863 224.755L392.295 234.179L396.847 242.94L414.164 244.519L416.659 248.539"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M330.926 170.858L334.436 180.259L338.066 187.572L341.852 192.2"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M346.806 164.526L350.457 173.315L354.249 180.085L358.204 184.42L362.338 186.188L378.797 180.394L383.137 183.546L387.477 188.793L391.812 195.905L396.163 204.569L400.541 214.35L404.979 224.712L409.503 235.02L414.163 244.519L431.307 247.248"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M362.608 160.202L366.417 168.226L370.372 174.329L374.495 178.333L390.721 173.841L395.164 176.293L399.635 180.248L404.083 186.416L408.515 194.505L412.953 204.145L417.412 214.849L421.932 226.052L426.543 237.103L431.306 247.248L433.02 250.139"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M378.304 158L382.286 165.02L386.42 170.25"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M126.781 224.164L124.953 216.428L123.097 207.531"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M211.58 236.712L209.02 229.302L206.475 219.786L203.947 208.979"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M322.273 317.985L317.928 312.267L313.599 310.661L309.324 311.958L307.034 313.77L302.993 316.954L300.047 319.282L296.092 322.406L292.381 325.336L290.407 326.893L287.927 328.385L284.607 330.381L281.016 332.535L273.258 337.201L271.625 338.183L268.017 338.557L264.513 337.184L261.117 332.041L257.807 322.054L254.563 310.07L251.378 297.44L248.243 285.147L245.161 273.901L242.134 264.185L239.167 256.221L236.264 250.009L233.427 245.295L230.649 241.622L227.926 237.412L225.23 230.24L222.561 220.79L219.918 209.869"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M400.655 248.772L396.846 242.94L379.323 243.684L361.583 248.181L343.643 258.022L325.659 270.939L307.75 284.296L289.945 296.09L272.238 304.944L254.563 310.07L236.855 311.22L219.034 308.708L201.055 303.397L182.881 296.486L164.42 286.005L145.665 273.467L126.678 261.51L107.581 252.152L105.444 237.776L103.464 226.177L101.701 218.142L100.133 213.781L98.8091 212.831"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M372.054 261.017L377.072 265.444L381.944 275.773"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M302.586 331.689L298.463 327.127"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M352.496 276.017L357.308 279.326L362.028 288.093"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M64.3217 202.67L58.978 200.462"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M283.017 342.702L279.138 338.926L275.341 337.814L271.625 338.183L269.265 339.045L262.852 341.389L252.914 345.029L234.17 347.134L231.056 345.17L228.002 341.161L224.991 333.637L221.996 321.967L219.034 308.708L216.121 295.243L213.256 282.532L210.457 271.265L207.728 261.825L205.075 254.317L202.498 248.55L199.997 244.085L183.163 243.38L180.896 239.663L178.639 234.624L176.355 226.822L174.049 217.079L171.754 206.24"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M386.121 251.176L391.449 251.452L396.657 256.915L401.664 268.514"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M332.977 293.388L337.556 295.721L342.102 302.882"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M64.1377 201.379L59.6675 199.236"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M263.525 350.118L259.901 346.76L240.653 350.107L221.307 348.837L218.269 346.635L215.307 344.671L212.416 341.839L209.567 336.951L206.725 328.705L203.877 316.661L201.055 303.397L198.272 290.241L195.554 278.105L192.912 267.597L190.352 259.015L187.878 252.358L185.486 247.334L183.164 243.38"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M184.623 338.535L181.97 334.602L179.41 331.754L176.914 329.025L175.449 328.315L174.256 327.729L158.474 320.036L157.346 319.483L155.1 315.278L152.827 308.936L150.483 299.388L148.063 286.536L145.666 273.467L143.344 261.586L141.136 251.772L139.058 244.481L137.121 239.756L135.309 237.255"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M204.658 346.966L201.766 343.212L198.967 340.64L196.244 338.27L194.871 337.613L178.818 329.937L176.914 329.025L174.456 325.385L171.999 319.721L169.498 310.862L166.948 298.623L164.42 286.004L161.957 274.265L159.586 264.185L157.319 256.199L155.165 250.389L153.114 246.515L146.512 243.076L136.307 237.77L135.309 237.255"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M244.033 353.384L240.653 350.107L237.366 348.376L234.17 347.134L222.566 345.615L215.617 344.709L215.307 344.671L213.767 344.15L200.595 339.729L196.472 338.345L196.244 338.269L193.569 335.042L190.916 329.801L188.253 321.311L185.551 309.272L182.882 296.486L180.256 284.187L177.706 273.19L175.249 263.984L172.878 256.758L170.605 251.392L156.847 247.551L153.114 246.515L151.156 244.009L150.472 243.705L145.525 241.519L136.139 237.369L135.174 237.07L134.105 236.473"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M224.443 352.293L221.307 348.838L218.849 348.132L211.504 346.017L201.766 343.212L199.525 342.235L188.062 337.255L181.97 334.602L180.115 333.772L161.935 325.629L161.772 325.57L160.324 325.054L141.743 318.468L139.671 315.408L137.636 311.942L135.585 307.103L116.044 302.556L96.5901 302.578L77.3311 307.309"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M164.344 329.519L161.935 325.629L159.613 322.602L157.346 319.483L153.185 317.893L137.637 311.942L117.889 308.019L98.2342 308.589L78.769 313.743"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M263.465 236.864L260.411 233.539L257.433 226.974L254.514 217.757L251.639 206.679"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M143.886 322.087L141.743 318.468L139.302 318.007L121.497 314.66L101.316 315.056L81.3027 319.77"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M130.199 233.935L128.566 230.235L118.497 226.312L118.464 226.302"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M166.297 244.297L164.214 240.938L162.12 235.844L159.966 227.886L157.791 217.931L155.599 206.842"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M63.5899 197.896L60.2534 196.144"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M123.358 317.779L121.497 314.66L119.69 311.741"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M87.644 205.594L90.2101 205.806L106.702 206.896L123.097 207.531L139.394 207.552L155.598 206.842L171.754 206.24L187.883 207.39L203.947 208.979L219.918 209.869L235.808 209.256L251.638 206.679L267.453 201.997L283.272 195.395L299.13 187.377L315.025 178.8L330.926 170.857L346.805 164.526L362.608 160.202L378.303 158"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M60.8179 192.427L62.7166 193.474L63.5901 197.896L63.8017 198.97L82.7623 207.032L92.2344 212.587L101.701 218.142L111.483 224.907L120.494 231.146L121.41 231.803L132.922 240.07L139.058 244.481L139.568 244.806L157.319 256.199L175.249 263.984L192.913 267.597L210.458 271.264L227.867 273.738L245.162 273.901L262.392 271.145L279.611 265.319L296.874 256.758L314.207 246.277L331.605 235.188L349.019 225.352L366.342 218.766L383.523 215.386L400.541 214.35L417.413 214.849"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M372.672 258.402L370.616 258.771L369.558 259.373L365.685 261.581L365.359 261.771L352.252 269.236L333.812 283.726L326.358 290.019L315.448 299.226L304.913 307.445L297.225 313.439L279.133 324.713L261.116 332.042L243.094 334.971L224.991 333.637L206.724 328.705L188.252 321.311L169.498 310.862L150.483 299.388L131.289 289.455L128.978 275.643L126.678 261.511L124.469 248.74L122.397 238.427L120.493 231.146L118.768 226.969L117.032 225.743"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M82.5232 321.382L81.3026 319.77L80.0711 317.589L78.769 313.743L77.3314 307.309L75.7147 297.549L73.8647 283.867L71.7652 265.981L69.5464 245.935L67.3872 226.73L65.445 210.607L64.615 204.753L64.5336 204.167L64.3221 202.67L64.1376 201.379L63.8013 198.97"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M90.7475 207.601L90.2104 205.806"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M385.285 251.626L383.913 250.4L366.005 254.55L347.863 264.635L329.662 278.328L311.537 292.775L293.531 305.795L275.639 315.864L257.807 322.054L239.953 324.018L221.996 321.967L203.877 316.662L185.551 309.273L166.948 298.623L148.063 286.536L128.978 275.643L109.817 267.901L90.7041 264.538L71.7651 265.981"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M110.457 220.844L109.903 219.309L106.605 218.191"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M56.9385 202.941L57.8987 203.028"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M56.9385 202.941L57.6871 203.397L64.615 204.753"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M64.5338 204.167L62.0491 203.397L58.3438 202.247"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M57.687 203.397L57.8986 203.028L58.3434 202.247L58.9782 200.462L59.6672 199.236L60.2531 196.144L60.8173 192.427L61.5279 190.567"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M352.497 276.017L343.692 283.851L332.977 293.388L328.415 294.707L323.928 297.826L320.755 300.631L310.637 309.56L307.186 312.609L305.141 314.416L302.309 316.542L287.634 327.566L286.517 328.407L284.021 329.774L271.451 336.675L268.587 338.243L268.017 338.557L249.561 344.199L231.056 345.171L212.416 341.84L211.081 341.362L193.569 335.042L192.37 334.434L176.41 326.373L174.457 325.385L173.442 324.859L155.1 315.278L135.586 307.103L133.486 299.931L114.108 294.479L94.8111 293.611L75.7148 297.549"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M356.945 268.595L356.099 269.284L349.957 274.264L347.386 276.348L338.229 283.78L329.435 291.581L324.715 295.769L319.588 300.321L309.188 309.028L301.094 315.799L282.752 328.461L264.513 337.184L246.29 341.421L228.002 341.161L209.568 336.951L190.916 329.801L190.048 329.34L171.999 319.721L152.827 308.936L133.486 299.93L131.289 289.455L112.036 282.95L92.8579 280.981L73.8647 283.867"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M332.977 293.388L323.912 301.466L313.599 310.661L311.705 312.169L304.512 317.882L294.394 325.927L290.52 328.342L285.605 331.412L281.227 334.141L275.341 337.814L270.784 339.642L265.994 341.568L256.359 345.436L237.366 348.376L229.488 347.66L218.269 346.635L214.537 345.474L200.041 340.971L198.967 340.64L197.741 340.082L186.701 335.069L180.326 332.172L179.41 331.754L176.67 330.49L160.243 322.895L159.613 322.602L155.116 320.98L140.002 315.528L139.671 315.408L134.219 314.41L119.69 311.741L117.889 308.019L116.045 302.556L114.108 294.478L112.036 282.95L109.817 267.901L107.582 252.152"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M62.716 193.475L62.499 192.384"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M238.938 256V151" stroke="black" />
		<path
			d="M275.173 128.655C273.521 135.146 257.642 140.238 238.298 140.238C218.602 140.238 202.499 134.959 201.348 128.3C200.661 130.803 200.709 133.488 200.919 136.065C201.372 142.234 206.453 144.865 211.806 146.77C220.243 149.673 229.407 150.581 238.298 150.581C247.19 150.581 256.353 149.764 264.791 146.77C269.872 144.956 274.045 142.688 275.315 137.88C275.497 136.972 275.665 136.155 275.679 135.339C275.718 133.092 275.569 130.846 275.174 128.655H275.173Z"
			fill="#FF003C"
		/>
		<path
			d="M238.298 140.238C258.742 140.238 275.314 134.551 275.314 127.536C275.314 120.521 258.742 114.835 238.298 114.835C217.854 114.835 201.282 120.521 201.282 127.536C201.282 134.551 217.854 140.238 238.298 140.238Z"
			fill="black"
		/>
		<path
			d="M275.569 128.671C275.657 128.297 275.702 127.919 275.702 127.536C275.702 120.196 259.272 114.447 238.298 114.447C217.324 114.447 200.894 120.196 200.894 127.536C200.894 127.788 200.914 128.038 200.952 128.286C200.258 130.89 200.334 133.64 200.532 136.093C201.002 142.479 206.167 145.175 211.68 147.137C219.173 149.715 227.88 150.968 238.298 150.968C249.092 150.968 257.551 149.75 264.921 147.135C269.709 145.425 274.328 143.132 275.69 137.978C275.778 137.685 276.051 136.222 276.065 135.352C276.103 133.182 275.973 130.942 275.569 128.671ZM238.298 115.222C258.495 115.222 274.927 120.746 274.927 127.536C274.927 134.327 258.495 139.85 238.298 139.85C218.102 139.85 201.669 134.326 201.669 127.536C201.669 120.747 218.101 115.222 238.298 115.222ZM275.29 135.338C275.276 136.154 275.101 136.975 274.937 137.791C273.663 142.591 269.245 144.768 264.661 146.405C257.378 148.99 249 150.193 238.298 150.193C227.967 150.193 219.343 148.953 211.936 146.405C206.673 144.532 201.742 141.979 201.305 136.033C201.145 134.059 201.066 131.89 201.421 129.785C204.385 136.017 219.52 140.625 238.298 140.625C256.752 140.625 271.688 136.174 275.011 130.105C275.255 131.879 275.32 133.628 275.29 135.338Z"
			fill="black"
		/>
		<path
			d="M237.936 136.971C255.974 136.971 270.597 132.584 270.597 127.173C270.597 121.761 255.974 117.375 237.936 117.375C219.897 117.375 205.274 121.761 205.274 127.173C205.274 132.584 219.897 136.971 237.936 136.971Z"
			fill="black"
		/>
		<path
			d="M237.935 137.359C219.403 137.359 204.886 132.885 204.886 127.173C204.886 121.461 219.404 116.987 237.935 116.987C256.467 116.987 270.984 121.461 270.984 127.173C270.984 132.885 256.467 137.359 237.935 137.359ZM237.935 117.762C220.441 117.762 205.661 122.072 205.661 127.173C205.661 132.274 220.441 136.584 237.935 136.584C255.429 136.584 270.209 132.274 270.209 127.173C270.209 122.072 255.429 117.762 237.935 117.762Z"
			fill="#FF003C"
		/>
		<path
			d="M183.627 105.27C185.996 102.143 186.881 98.191 186.056 94.4284C184.65 87.6642 178.283 83.1475 171.503 83.8566C176.685 79.2492 181.212 74.5078 181.889 68.2427C182.402 63.378 180.311 58.2033 176.153 54.0447C172.734 50.6269 168.418 48.312 164.69 46.638C168.893 46.5822 172.927 46.0591 176.702 45.0771C180.026 44.1913 184.77 42.5537 187.625 38.5772C189.958 35.3276 190.508 30.6769 189.135 25.8161C188.051 21.9798 185.964 18.5675 183.843 15.2861C189.489 17.6778 194.391 22.589 200.557 22.589C204.72 22.8199 209.746 19.9478 211.48 14.8049C211.96 13.3952 212.155 11.9039 212.053 10.4181C211.95 8.93226 211.553 7.48173 210.884 6.15122C210.838 6.05924 210.757 5.98927 210.66 5.95672C210.562 5.92416 210.456 5.93168 210.364 5.97762C210.272 6.02356 210.202 6.10415 210.169 6.20168C210.137 6.2992 210.144 6.40566 210.19 6.49764C210.813 7.73678 211.183 9.08767 211.279 10.4714C211.374 11.8552 211.192 13.244 210.745 14.5569C209.142 19.3123 204.47 22.031 200.578 21.8147C194.247 21.814 189.211 16.3192 182.994 14.1244C182.917 14.0974 182.835 14.0949 182.757 14.1175C182.679 14.14 182.61 14.1863 182.56 14.2499C182.51 14.3136 182.481 14.3913 182.477 14.4722C182.474 14.5531 182.495 14.6331 182.54 14.701C184.843 18.2451 187.225 21.9101 188.389 26.0269C189.698 30.659 189.19 35.068 186.995 38.1246C182.426 44.4905 170.63 46.0126 162.821 45.8568C162.406 45.8405 162.266 46.4334 162.662 46.6008C166.657 48.2903 171.738 50.7261 175.604 54.5926C179.537 58.5257 181.598 63.5973 181.118 68.1597C180.427 74.5442 175.464 79.3655 169.998 84.1488C169.699 84.4099 169.942 84.899 170.331 84.8199C177.172 83.4172 183.887 87.799 185.297 94.5896C186.075 98.1344 185.241 101.854 183.009 104.801C182.696 105.213 183.323 105.668 183.626 105.269L183.627 105.27Z"
			fill="black"
		/>
		<path
			d="M272.685 116.673C272.777 116.673 272.866 116.64 272.936 116.58C273.006 116.52 273.053 116.437 273.067 116.345C273.783 111.78 278.739 109.052 283.118 109.052C287.057 109.052 291.131 110.834 295.573 114.498C295.926 114.789 296.405 114.329 296.128 113.965C293.008 109.865 291.027 104.941 290.397 99.727C289.937 96.0442 290.444 93.3495 291.947 91.4888C294.928 87.8346 300.771 88.5267 303.989 89.2637C304.367 89.3505 304.624 88.8871 304.35 88.612C301.871 86.1335 299.894 83.0134 298.632 79.5886C295.749 70.7575 302.72 64.4436 310.206 61.4839C310.599 61.3281 310.491 60.7399 310.067 60.736C300.765 60.6469 292.061 55.6783 287.352 47.7702C282.818 39.7691 282.818 29.7639 287.357 21.6659C287.396 21.5965 287.413 21.5168 287.405 21.4375C287.397 21.3583 287.365 21.2835 287.313 21.2232C287.261 21.1629 287.191 21.1201 287.114 21.1005C287.037 21.081 286.956 21.0858 286.881 21.1141C278.932 24.1327 271.009 24.3598 264.573 21.7519C257.037 18.7 251.951 11.5777 252.204 4.43302C252.222 3.9254 251.447 3.8851 251.43 4.4059C251.166 11.8691 256.452 19.2983 264.282 22.4704C270.64 25.0457 278.387 24.9403 286.197 22.1898C282.016 30.3552 282.178 40.2124 286.681 48.1601C291.242 55.8194 299.432 60.7972 308.349 61.442C301.446 64.6761 295.057 71.1365 297.9 79.8436C299.056 82.9824 300.789 85.8661 302.947 88.2609C299.443 87.6316 294.234 87.4595 291.345 91.0005C289.701 93.0357 289.14 95.9202 289.628 99.8216C290.17 104.32 291.695 108.608 294.07 112.361C290.239 109.617 286.639 108.277 283.117 108.277C278.413 108.277 273.081 111.25 272.3 116.225C272.292 116.281 272.295 116.337 272.31 116.391C272.326 116.445 272.353 116.495 272.389 116.537C272.426 116.58 272.471 116.614 272.522 116.637C272.573 116.66 272.628 116.672 272.684 116.672L272.685 116.673Z"
			fill="black"
		/>
		<path
			d="M234.626 79.176V68.104C238.85 68.0187 242.093 66.9947 244.354 65.032C246.658 63.0693 247.81 60.5307 247.81 57.416V55.752C247.81 52.936 246.872 50.6533 244.994 48.904C243.117 47.112 240.472 46.216 237.058 46.216C233.773 46.216 231.17 46.984 229.25 48.52C227.373 50.0133 226.029 52.168 225.218 54.984L223.938 54.664C224.792 51.592 226.306 49.224 228.482 47.56C230.658 45.8533 233.517 45 237.058 45C240.898 45 243.885 46.0453 246.018 48.136C248.194 50.2267 249.282 53 249.282 56.456C249.282 60.2107 248.13 63.1973 245.826 65.416C243.565 67.6347 240.301 68.936 236.034 69.32V79.176H234.626ZM235.33 90.76C233.88 90.76 233.154 90.184 233.154 89.032V88.52C233.154 87.368 233.88 86.792 235.33 86.792C236.781 86.792 237.506 87.368 237.506 88.52V89.032C237.506 90.184 236.781 90.76 235.33 90.76Z"
			fill="black"
		/>
	</svg>
);

const listStyle = "!cursor-pointer hover:font-medium duration-200 ease-in-out";

export default function Footer({ platform = false, faq = false }) {
	const dispatch = useDispatch();
	const { wallet, showWallet } = useSelector(selectWallet);

	// to update bg image
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// handle wallet connection
	const handleWallet = () => {
		if (showWallet) {
			dispatch(hide());
			return;
		}

		dispatch(show());
	};

	return (
		<div className="bg-[#FF003C]">
			{/* svg */}
			<img
				src={windowWidth >= 768 ? footerTop : footerMobileTop}
				alt=""
				className="w-full object-cover bg-black"
			/>

			{/* text & footer */}
			<div className="">
				{/* text */}
				{faq ? (
					<FaqFooter />
				) : (
					<div
						style={{ backgroundImage: `url(${footerBg})` }}
						className="
						bg-no-repeat bg-cover
						flex flex-col items-center
						py-20
						px-8 md:px-16
						"
					>
						<p
							style={{ lineHeight: windowWidth >= 768 ? "44px" : "40px" }}
							className="text-4xl md:text-[42px] text-black 
                        w-full md:max-w-[690px]
                         text-center"
						>
							{platform
								? "Dive deep into the world of sports like never before"
								: "Ready to seize the future of sports betting?"}
						</p>

						<div className="w-full md:max-w-[550px] mt-10">
							<Plus className="stroke-black" />
							<p className=" mt-3 opacity-75 font-normal">
								Join Vig today and unlock a new realm of possibilities. Amplify
								your potential payouts with our cutting-edge leveraged 'sports
								futures' algorithms, and embrace the thrill of high-risk,
								high-reward wagering
							</p>

							<div className="flex items-center mt-5">
								<div className="h-[2px] bg-black flex-1" />
								<Plus className="stroke-black ml-2" />
							</div>
						</div>

						{/* wallet button */}
						{!wallet ? (
							<ConnectWallet isBlack onClick={() => handleWallet()} />
						) : (
							<ConnectedWallet
								isBlack
								avatar={avatar}
								wallet={wallet}
								onClick={() => handleWallet()}
							/>
						)}
					</div>
				)}

				{/* footer */}
				<div
					className="flex flex-wrap justify-between
					px-8 md:px-16 pb-10 pt-24
					"
				>
					{/* logo & lists */}
					<div className="flex justify-between w-full md:w-[50%] lg:w-[30%] max-md:mb-8">
						{/* logo */}
						<Logo dark={true} />

						{/* list 1 */}
						<div className="flex flex-col mx-2">
							<h1
								className="font-medium mb-4 cursor-default"
								style={{ lineHeight: "15px" }}
							>
								Resources
							</h1>

							<p className={listStyle} onClick={() => {}}>
								About
							</p>
							<p className={listStyle} onClick={() => {}}>
								Contact
							</p>
							<p className={listStyle} onClick={() => {}}>
								Developers
							</p>
							<p className={listStyle} onClick={() => {}}>
								Resources
							</p>
						</div>

						{/* list 2 */}
						<div className="flex flex-col">
							<h1
								className="font-medium mb-4 cursor-default"
								style={{ lineHeight: "15px" }}
							>
								Company
							</h1>

							<p className={listStyle} onClick={() => {}}>
								Privacy
							</p>
							<p className={listStyle} onClick={() => {}}>
								Terms
							</p>
						</div>
					</div>

					{/* social icons */}
					<div className="w-full h-fit md:w-fit flex md:flex-col items-center justify-between max-md:mb-8">
						<h1 className="text-lg font-light">© 2023 VIG</h1>

						<div className="flex max-md:ml-auto md:mt-3">
							{/* fb */}
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="cursor-pointer"
							>
								<path
									d="M22 12.0607C22 6.504 17.5233 2 12 2C6.47667 2 2 6.504 2 12.0607C2 17.0833 5.656 21.2453 10.4373 22V14.9693H7.89867V12.06H10.4373V9.844C10.4373 7.32267 11.93 5.92933 14.2147 5.92933C15.308 5.92933 16.4533 6.126 16.4533 6.126V8.602H15.1913C13.9493 8.602 13.5627 9.378 13.5627 10.174V12.0607H16.336L15.8927 14.9687H13.5627V22C18.344 21.2453 22 17.0833 22 12.0607Z"
									fill="black"
								/>
							</svg>

							{/* linkedin */}
							<svg
								className="mx-2 cursor-pointer"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M21 4.32353V19.6765C21 20.0275 20.8606 20.3641 20.6123 20.6123C20.3641 20.8606 20.0275 21 19.6765 21H4.32353C3.97251 21 3.63586 20.8606 3.38765 20.6123C3.13944 20.3641 3 20.0275 3 19.6765V4.32353C3 3.97251 3.13944 3.63586 3.38765 3.38765C3.63586 3.13944 3.97251 3 4.32353 3H19.6765C20.0275 3 20.3641 3.13944 20.6123 3.38765C20.8606 3.63586 21 3.97251 21 4.32353ZM8.29412 9.88235H5.64706V18.3529H8.29412V9.88235ZM8.53235 6.97059C8.53375 6.77036 8.49569 6.57182 8.42035 6.3863C8.34502 6.20078 8.23387 6.03191 8.09328 5.88935C7.95268 5.74678 7.78537 5.6333 7.60091 5.5554C7.41646 5.47749 7.21846 5.43668 7.01824 5.43529H6.97059C6.5634 5.43529 6.17289 5.59705 5.88497 5.88497C5.59705 6.17289 5.43529 6.5634 5.43529 6.97059C5.43529 7.37777 5.59705 7.76828 5.88497 8.05621C6.17289 8.34413 6.5634 8.50588 6.97059 8.50588C7.17083 8.51081 7.37008 8.47623 7.55696 8.40413C7.74383 8.33202 7.91467 8.2238 8.0597 8.08565C8.20474 7.94749 8.32113 7.78212 8.40223 7.59897C8.48333 7.41582 8.52755 7.21848 8.53235 7.01824V6.97059ZM18.3529 13.2071C18.3529 10.6606 16.7329 9.67059 15.1235 9.67059C14.5966 9.6442 14.0719 9.75644 13.6019 9.9961C13.1318 10.2358 12.7328 10.5945 12.4447 11.0365H12.3706V9.88235H9.88235V18.3529H12.5294V13.8476C12.4911 13.3862 12.6365 12.9283 12.9339 12.5735C13.2313 12.2186 13.6567 11.9954 14.1176 11.9524H14.2182C15.06 11.9524 15.6847 12.4818 15.6847 13.8159V18.3529H18.3318L18.3529 13.2071Z"
									fill="black"
								/>
							</svg>

							{/* discord */}
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="cursor-pointer"
							>
								<path
									d="M18.9308 5.64149C17.6561 5.05658 16.2892 4.62565 14.8599 4.37884C14.8339 4.37408 14.8079 4.38598 14.7945 4.40979C14.6187 4.72247 14.4239 5.13039 14.2876 5.45101C12.7503 5.22087 11.221 5.22087 9.71527 5.45101C9.57887 5.12326 9.37707 4.72247 9.20048 4.40979C9.18707 4.38678 9.16107 4.37487 9.13504 4.37884C7.70659 4.62487 6.33963 5.0558 5.06411 5.64149C5.05307 5.64625 5.04361 5.65419 5.03732 5.6645C2.44449 9.53813 1.73421 13.3166 2.08265 17.0481C2.08423 17.0664 2.09447 17.0839 2.10867 17.095C3.81934 18.3512 5.47642 19.1139 7.10273 19.6194C7.12876 19.6274 7.15634 19.6179 7.1729 19.5964C7.55761 19.0711 7.90054 18.5171 8.19456 17.9346C8.21192 17.9005 8.19535 17.86 8.15989 17.8465C7.61594 17.6402 7.098 17.3886 6.59977 17.1029C6.56037 17.0799 6.55721 17.0235 6.59347 16.9965C6.69831 16.918 6.80318 16.8362 6.9033 16.7537C6.92141 16.7386 6.94665 16.7354 6.96794 16.745C10.2411 18.2393 13.7846 18.2393 17.0191 16.745C17.0404 16.7346 17.0657 16.7378 17.0846 16.7529C17.1847 16.8354 17.2895 16.918 17.3952 16.9965C17.4314 17.0235 17.4291 17.0799 17.3897 17.1029C16.8914 17.3941 16.3735 17.6402 15.8288 17.8457C15.7933 17.8592 15.7775 17.9005 15.7949 17.9346C16.0952 18.5163 16.4381 19.0702 16.8157 19.5956C16.8315 19.6179 16.8599 19.6274 16.8859 19.6194C18.5201 19.1139 20.1772 18.3512 21.8879 17.095C21.9028 17.0839 21.9123 17.0672 21.9139 17.0489C22.3309 12.7348 21.2154 8.98737 18.9568 5.66529C18.9513 5.65419 18.9419 5.64625 18.9308 5.64149ZM8.68335 14.776C7.69792 14.776 6.88594 13.8713 6.88594 12.7602C6.88594 11.6491 7.68217 10.7444 8.68335 10.7444C9.69239 10.7444 10.4965 11.6571 10.4807 12.7602C10.4807 13.8713 9.68451 14.776 8.68335 14.776ZM15.329 14.776C14.3435 14.776 13.5316 13.8713 13.5316 12.7602C13.5316 11.6491 14.3278 10.7444 15.329 10.7444C16.338 10.7444 17.1421 11.6571 17.1264 12.7602C17.1264 13.8713 16.338 14.776 15.329 14.776Z"
									fill="black"
								/>
							</svg>
						</div>
					</div>

					{/* arrow */}
					<svg
						width="42"
						height="42"
						viewBox="0 0 42 42"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="cursor-pointer max-md:ml-auto hover:opacity-80 duration-300 ease-in-out"
						onClick={() =>
							window.scrollTo({
								top: 0,
								behavior: "smooth",
							})
						}
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M33.0296 0L42 8.97042V42H8.96972L0 33.0303V0H33.0296Z"
							fill="black"
						/>
						<path
							d="M21 28V14M21 14L14 21M21 14L28 21"
							stroke="white"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
}

function FaqFooter() {
	const navigate = useNavigate();

	// to update bg image
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div
			style={{
				backgroundImage: `url(${footerBg})`,
				backgroundPositionY: windowWidth <= 768 ? "150px" : "0px",
			}}
			className="bg-no-repeat bg-cover
            flex md:px-16
			"
		>
			<div
				className="flex flex-col max-md:items-center
                    py-20 px-8 md:px-16 
					md:flex-1 overflow-hidden"
			>
				{/* heading */}
				<p
					style={{ lineHeight: windowWidth >= 768 ? "44px" : "40px" }}
					className="text-4xl md:text-[42px] text-black 
                    w-full md:max-w-[690px]
                    md:pt-6"
				>
					Have more questions?
				</p>

				{/* svg small */}
				<div className="lg:hidden my-4 max-sm:-ml-6 sm:mx-auto">{svgSmall}</div>

				{/* text */}
				<div className="w-full lg:max-w-[510px] mt-10">
					<Plus className="stroke-black" />
					<p className=" mt-3 opacity-75 font-normal text-sm">
						Ethereum is constantly evolving and we’re here to help along the
						way. If you have additional questions, feel free to reach out.
					</p>

					<div className="flex items-center mt-5">
						<div className="h-[2px] bg-black flex-1" />
						<Plus className="stroke-black ml-2" />
					</div>
				</div>

				{/* button */}
				<button
					className="h-12 w-full lg:w-40 
                        mt-10 
						flex items-center justify-center
                         text-sm 
						text-white bg-black
                        hover:opacity-70 duration-300 ease-in-out
                        z-0
                        "
					onClick={() => navigate("/contact")}
				>
					Contact Us&nbsp;
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M4 12H20M20 12L14 6M20 12L14 18"
							stroke="white"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>

			{/* big svg */}
			<div className="flex items-center justify-center flex-1 max-lg:hidden">
				{svgBig}
			</div>
		</div>
	);
}
