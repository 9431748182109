import React from "react";

export default function Logo({ className, dark = false, ...props }) {
	return (
		<div className={`Logo cursor-pointer ${className}`} {...props}>
			{!dark ? (
				<svg
					width="56"
					height="37"
					viewBox="0 0 56 37"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M56 0H0V14.3194V14.5L1.90224 19.5353L0 14.3194H4.80935L11.1076 32.7072H11.3557L17.6422 14.3194H22.4633L14.1803 37H24.3198V14.3194H28.7038V37H37.7256C37.4249 36.8643 37.1319 36.7147 36.8467 36.5513C35.1688 35.5779 33.8571 34.1977 32.9118 32.4106C31.9743 30.616 31.5056 28.4867 31.5056 26.0228C31.5056 24.1369 31.7813 22.4525 32.3328 20.9696C32.8921 19.4867 33.672 18.2281 34.6725 17.1939C35.6729 16.1521 36.8467 15.3612 38.1938 14.8213C39.5409 14.2738 41.0062 14 42.5896 14C43.9288 14 45.1774 14.1901 46.3354 14.5703C47.4935 14.943 48.5215 15.4753 49.4196 16.1673C50.3255 16.8593 51.07 17.6806 51.6529 18.6312C52.2359 19.5817 52.6179 20.6312 52.7991 21.7795H48.3324C48.1355 21.1635 47.8637 20.6122 47.5171 20.1255C47.1784 19.6312 46.7687 19.2091 46.2882 18.8593C45.8155 18.5095 45.2719 18.2471 44.6575 18.0722C44.043 17.8897 43.3734 17.7985 42.6487 17.7985C41.3488 17.7985 40.1908 18.1141 39.1746 18.7452C38.1584 19.3764 37.3588 20.3042 36.7758 21.5285C36.2008 22.7452 35.9132 24.2281 35.9132 25.9772C35.9132 27.7414 36.2008 29.2357 36.7758 30.4601C37.3509 31.6844 38.1505 32.616 39.1746 33.2548C40.1987 33.8859 41.3882 34.2015 42.7432 34.2015C43.9721 34.2015 45.0356 33.9734 45.9337 33.5171C46.8396 33.0608 47.5368 32.4144 48.0252 31.5779C48.4922 30.7709 48.736 29.8317 48.7564 28.7605H43.1804V25.4525H53V28.2586C53 30.2586 52.5588 31.9886 51.6765 33.4487C50.7942 34.9087 49.5811 36.0342 48.037 36.8251C47.9175 36.8857 47.7966 36.944 47.6743 37H56V0Z"
						fill="#FF003C"
					/>
				</svg>
			) : (
				<svg
					width="56"
					height="37"
					viewBox="0 0 56 37"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M56 0H0V14.3194V14.5L1.90224 19.5353L0 14.3194H4.80935L11.1076 32.7072H11.3557L17.6422 14.3194H22.4633L14.1803 37H24.3198V14.3194H28.7038V37H37.7256C37.4249 36.8643 37.1319 36.7147 36.8467 36.5513C35.1688 35.5779 33.8571 34.1977 32.9118 32.4106C31.9743 30.616 31.5056 28.4867 31.5056 26.0228C31.5056 24.1369 31.7813 22.4525 32.3328 20.9696C32.8921 19.4867 33.672 18.2281 34.6725 17.1939C35.6729 16.1521 36.8467 15.3612 38.1938 14.8213C39.5409 14.2738 41.0062 14 42.5896 14C43.9288 14 45.1774 14.1901 46.3354 14.5703C47.4935 14.943 48.5215 15.4753 49.4196 16.1673C50.3255 16.8593 51.07 17.6806 51.6529 18.6312C52.2359 19.5817 52.6179 20.6312 52.7991 21.7795H48.3324C48.1355 21.1635 47.8637 20.6122 47.5171 20.1255C47.1784 19.6312 46.7687 19.2091 46.2882 18.8593C45.8155 18.5095 45.2719 18.2471 44.6575 18.0722C44.043 17.8897 43.3734 17.7985 42.6487 17.7985C41.3488 17.7985 40.1908 18.1141 39.1746 18.7452C38.1584 19.3764 37.3588 20.3042 36.7758 21.5285C36.2008 22.7452 35.9132 24.2281 35.9132 25.9772C35.9132 27.7414 36.2008 29.2357 36.7758 30.4601C37.3509 31.6844 38.1505 32.616 39.1746 33.2548C40.1987 33.8859 41.3882 34.2015 42.7432 34.2015C43.9721 34.2015 45.0356 33.9734 45.9337 33.5171C46.8396 33.0608 47.5368 32.4144 48.0252 31.5779C48.4922 30.7709 48.736 29.8317 48.7564 28.7605H43.1804V25.4525H53V28.2586C53 30.2586 52.5588 31.9886 51.6765 33.4487C50.7942 34.9087 49.5811 36.0342 48.037 36.8251C47.9175 36.8857 47.7966 36.944 47.6743 37H56V0Z"
						fill="black"
					/>
				</svg>
			)}
		</div>
	);
}
